<template>
  <div class="stepper-input-summary">
    <typography type="h2" no-margin bold class="stepper-count">
      {{ count }}
    </typography>
    <typography light no-margin>
      Total
    </typography>
  </div>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';

export default {
  components: {
    Typography
  },
  props: {
    count: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.stepper-input-summary {
  background-image: linear-gradient(-180deg, #271d90 0%, #1e196a 100%);
  //box-shadow: 0 0 14px 0 #e84b00;
  border-radius: rem(11px);
  padding: rem(10px 55px);
  margin: rem(0 35px);
  user-select: none;
  color: $white;
}

.stepper-count {
  width: 40px;
  text-align: center;
}
</style>
