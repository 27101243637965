<template>
  <full-page-gradient color="orange">
    <update-cards-dialog />
    <too-many-cards-dialog
      @onCancel="onTooManyCardsDialogOnCancel"
      @onContinue="onTooManyCardsDialogOnContinue"
      cancelLabel="Yes, I will send email"
    />
    <mobile-full-page-wrapper
      :go-to-id="nextStepsId"
      show-arrow
      arrow-color="white"
    >
      <typography type="tag">Next steps</typography>
      <typography type="h3"
        >Nice, we've successfully received your application.
        <br />
        <template v-if="shouldBeApproved"
          >You're officially a member of the
          <span class="non-breaking">{{ brand }}</span> programme.</template
        >
        <template v-else
          >You're on your way to becoming part of the
          <span class="non-breaking">{{ brand }}</span> programme.</template
        >

        <br />Please confirm your card order below.
      </typography>
    </mobile-full-page-wrapper>

    <mobile-full-page-wrapper :id="nextStepsId">
      <!-- Refer message -->
      <v-layout justify-center v-if="!shouldBeApproved">
        <v-flex sm7 md7 lg5>
          <typography no-margin
            >We’re processing your application and will be in touch in the next two business days if we need more information.</typography
          >
        </v-flex>
      </v-layout>

      <!-- Num cards total -->
      <v-layout justify-center>
        <v-flex lg10 class="card-info no-user-select">
          <v-layout justify-center>
            <v-flex sm6>
              <typography type="h5" no-margin>
                <span class="num-cards">
                  Order cards
                </span>
              </typography>
              <typography type="h5" no-margin>
                You have
                <span class="num-cards" name="nextStepsCardsCount"
                  >{{ numCards }} fuel card{{ numCards > 1 ? 's' : '' }}</span
                >
                in total
              </typography>
              <stepper-input
                :count="numCards"
                :remove-button-disabled="numCards === 1"
                @remove="onRemoveCard"
                @add="onAddCard"
                name="changeCardsStepper"
              />
            </v-flex>
          </v-layout>

          <!-- Banner -->
          <v-layout justify-center>
            <v-flex sm8 md10>
              <typography type="h5"
                >Your ready-to-use {{ brand }} fuel card{{
                  numCards > 1 ? 's' : ''
                }}
                can be used with</typography
              >
              <ready-to-use-z-card-info />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-layout
        :mt-5="$vuetify.breakpoint.smAndUp"
        justify-center
        v-if="shouldBeApproved"
      >
        <v-flex xs10 md12 lg11>
          <processing-steps />
        </v-flex>
      </v-layout>
    </mobile-full-page-wrapper>
  </full-page-gradient>
</template>

<script>
import UpdateCardsDialog from '@/components/organisms/UpdateCardsDialog.vue';
import FullPageGradient from '@/components/templates/FullPageGradient.vue';
import Typography from '@/components/atoms/Typography.vue';
import ProcessingSteps from '@/components/molecules/ProcessingSteps.vue';
import HorizontalLine from '@/components/atoms/HorizontalLine.vue';
import StepperInput from '@/components/molecules/StepperInput.vue';
import ReadyToUseZCardInfo from '@/components/molecules/ReadyToUseZCardInfo.vue';
import MobileFullPageWrapper from '@/components/molecules/MobileFullPageWrapper.vue';
import { HOME } from '@/constants/routes';
import { mapState, mapGetters } from 'vuex';
import { NEXT_STEPS } from '@/constants/html-ids';
import {
  TOO_MANY_NEW_CARDS_THRESHOLD,
  CREDIT_CHECK_APPROVE_WITH_PG,
  CREDIT_CHECK_APPROVED,
  BRAND
} from '@/constants/form';
import TooManyCardsDialog from '@/components/organisms/TooManyCardsDialog.vue';

export default {
  components: {
    UpdateCardsDialog,
    Typography,
    FullPageGradient,
    ProcessingSteps,
    HorizontalLine,
    StepperInput,
    ReadyToUseZCardInfo,
    MobileFullPageWrapper,
    TooManyCardsDialog
  },
  computed: {
    ...mapState({
      cards: state => state.cardPreferences.cards
    }),
    ...mapGetters({
      numCards: 'cardPreferences/numCards'
    }),
    shouldBeApproved: function() {
      // true if credit check is approved or approve with pg and agreed to credit check is true
      const credit = this.$store.state.credit;
      if (
        (credit.creditCheckResult &&
          credit.creditCheckResult === CREDIT_CHECK_APPROVED) ||
        (credit.creditCheckResult === CREDIT_CHECK_APPROVE_WITH_PG &&
          credit.agreedToPersonalGuarantee)
      ) {
        return true;
      }

      return false;
    },
    nextStepsId: () => NEXT_STEPS,
    brand: () => BRAND
  },
  methods: {
    onHomeClick() {
      window.location.href = HOME;
    },
    onAddCard() {
      if (this.numCards === TOO_MANY_NEW_CARDS_THRESHOLD) {
        this.$store.dispatch('ui/toggleTooManyCardsDialogOpen', true);
      }
      this.$store.dispatch('cardPreferences/addCard');
    },
    onRemoveCard() {
      this.$store.dispatch(
        'cardPreferences/changeCardIndexToRemove',
        this.numCards - 1
      );
      this.$store.dispatch('cardPreferences/removeCard');
    },
    onTooManyCardsDialogOnCancel() {
      window.location.href = HOME;
    },
    onTooManyCardsDialogOnContinue() {
      this.$store.dispatch('ui/toggleTooManyCardsDialogOpen', false);
    },
    onUnload(event) {
      event.preventDefault();
      event.returnValue = '';
      // this message is only display in IE, other browsers have a generic message
      return "Are you sure you want to leave without confirming card order?";
    }
  },
  mounted() {
    window.addEventListener("beforeunload", this.onUnload);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.onUnload);
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.num-cards {
  color: $dark-blue;
  font-weight: $weight-bold;
}

.change-cards-link {
  color: $white;
  text-decoration: underline;
}

.no-user-select {
  user-select: none;
}
.non-breaking {
  white-space: nowrap;
}

.card-info {
  position: relative;
  background: $white;
  color: $dark-blue;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
  border-radius: rem(10px);
  margin: rem(80px 0 40px);
  padding: rem(20px);

  @include md {
    padding: rem(40px 80px 30px 80px);
  }
}
</style>
