<template>
  <div
    :class="[
      'stepper-input-button',
      { 'stepper-input-button--disabled': disabled }
    ]"
    @click="onClick"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      this.$emit('click');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.stepper-input-button {
  outline: 0;
  background: $orange;
  box-shadow: 0 0 29px 0 #a0532d;
  border-radius: rem(5px);
  width: rem(60px);
  height: rem(60px);
  padding: rem(0 15px);
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  /deep/ svg {
    width: rem(28px);

    path,
    g {
      stroke: $white;
    }
  }

  &--disabled {
    pointer-events: none;

    /deep/ svg {
      path {
        stroke: $mid-charcoal;
      }
    }
  }
}
.stepper-input-button--disabled {
  opacity: 0.5;
}
</style>
