<template>
  <stepper :steps="steps" />
</template>

<script>
import Stepper from '@/components/molecules/Stepper.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    Stepper
  },
  data() {
    return {
      steps: [
        'We are processing your application and will email you in the next 24 hours.',
        'Your Z online account will be active in the next 3 working days.',
        `Keep an eye out for your ${
          this.brand
        } card which should arrive in 5 - 7 working days!`
      ]
    };
  },
  computed: {
    ...mapGetters({
      brand: 'saver/brand'
    })
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';
</style>
