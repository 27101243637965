<template>
  <div>
    <z-card
      v-if="showCard && $vuetify.breakpoint.mdAndUp"
      class="z-card"
      label="Any"
      black-shadow
    />
    <v-layout class="icons" justify-center wrap>
      <v-flex xs6 md4 d-inline-flex @click="onManageCardsClick">
        <icon-and-text class="pointer" text="Any vehicle" bold text-type="body" >
          <vehicle-icon />
        </icon-and-text>
      </v-flex>
      <v-flex xs6 md4 d-inline-flex>
        <icon-and-text class="pointer" text="Any driver" bold text-type="body">
          <drivers-icon />
        </icon-and-text>
      </v-flex>
      <v-flex xs6 md4 d-inline-flex>
        <icon-and-text class="pointer" text="All fuel types" bold text-type="body">
          <fuel-icon />
        </icon-and-text>
      </v-flex>
      <v-flex
        xs6
        d-inline-flex
        align-center
        v-if="$vuetify.breakpoint.smAndDown"
      >
        <typography class="note" type="small-p"
          >All in-store and product purchases enabled</typography
        >
      </v-flex>
    </v-layout>
    <typography
      class="note mt-4"
      type="small-p"
      v-if="$vuetify.breakpoint.mdAndUp"
      >All in-store and product purchases enabled</typography
    >

    <!-- Manage Cards Button -->
    <v-layout class="buttons" justify-center wrap>
      <v-flex order-xs2 order-md1 xs10 sm7 md4 lg4 mb-4>
        <round-button
          
          @click="onConfirmClick"
          name="changeCardsNoThanksButton"
          >Confirm Order</round-button
        >
      </v-flex>
      <v-flex order-xs1 order-md2 xs10 sm7 md4 lg4 mb-4>
        <round-button 
          outline
          @click="onManageCardsClick"
          name="changeCardsManageButton"
          >Manage cards</round-button
        >
        <tooltip top class="tooltip-container">
          <a slot="activator" href="#" @click.prevent>What's this?</a>
          <div class="tooltip-content">
            <typography type="small-p" bold no-margin
              >You can assign card type, products enabled and spending limit for
              individual cards.</typography
            >
          </div>
        </tooltip>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import IconAndText from '@/components/molecules/IconAndText.vue';
import Typography from '@/components/atoms/Typography.vue';
import DriversIcon from '@/assets/icons/drivers.svg';
import VehicleIcon from '@/assets/icons/vehicle.svg';
import FuelIcon from '@/assets/icons/fuel.svg';
import ZCard from '@/components/molecules/ZCard.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';
import Tooltip from '@/components/atoms/Tooltip.vue';
import { CARD_PREFERENCES } from '@/constants/routes';

export default {
  components: {
    IconAndText,
    Typography,
    DriversIcon,
    VehicleIcon,
    FuelIcon,
    RoundButton,
    Tooltip,
    ZCard
  },
  methods: {
    onConfirmClick() {
      this.$store.dispatch('ui/toggleUpdateCardsDialogOpen', true);
    },
    onManageCardsClick() {
      this.$router.push(CARD_PREFERENCES);
    }
  },
  props: {
    showCard: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.z-card {
  position: absolute;
  bottom: rem(-30px);
  left: rem(-30px);
  transform: scale(0.7);
  transform-origin: bottom left;
}

.icons {
  margin-top: rem(20px);
}

.limit {
  line-height: normal;
  text-align: center;

  &__amount {
    margin: rem(0 30px);
    font-size: rem(38px);
    font-weight: $weight-bold;
  }
}

.note {
  color: $note-color;
}
.buttons {
  margin-top: rem(32px);
  color: $orange;
}
.tooltip-container {
  display: block;
  margin-top: 10px;
}
.tooltip-content {
  color: $charcoal;
  text-align: center;
}
.pointer {
  cursor: pointer;
}
</style>
