<template>
  <div class="stepper">
    <v-layout justify-center wrap>
      <v-flex v-for="(step, index) in steps" :key="index" xs12 sm7 md4>
        <div :class="['step', { 'step--last': index === steps.length - 1 }]">
          <v-layout
            :pr-5="$vuetify.breakpoint.mdAndUp"
            :pl-5="$vuetify.breakpoint.mdAndUp"
          >
            <span class="step__dot" />
            <span class="step__text">
              <typography no-margin>
                {{ step }}
              </typography>
            </span>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';

export default {
  components: {
    Typography
  },
  props: {
    steps: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.stepper {
  text-align: left;

  @include md {
    text-align: center;
  }
}

.step {
  position: relative;
  padding: rem(0 0 30px 40px);

  @include md {
    padding: rem(30px 0 0 0);
  }

  &__dot {
    position: absolute;
    left: rem(8px);
    top: rem(8px);
    display: inline-block;
    width: rem(10px);
    height: rem(10px);
    border-radius: 50%;
    background-color: $white;

    @include md {
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: rem(13px);
    top: rem(10px);
    height: 100%;
    border-left: 1px dashed $white;
    transform: translate(-50%, 0);

    @include md {
      width: 100%;
      height: 0;
      border-left: 0;
      border-top: 1px dashed $white;
      top: rem(13px);
      left: 50%;
      transform: translate(0, -50%);
    }
  }

  &--last {
    &:before {
      display: none;
    }
  }
}
</style>
