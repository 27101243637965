<template>
  <div class="stepper-input">
    <stepper-input-button
      :disabled="removeButtonDisabled"
      @click="onRemoveClick"
      :name="name + 'Remove'"
    >
      <minus-icon />
    </stepper-input-button>
    <stepper-input-summary :count="count" :name="name + 'Count'" />
    <stepper-input-button
      :disabled="addButtonDisabled"
      @click="onAddClick"
      :name="name + 'Add'"
    >
      <add-icon />
    </stepper-input-button>
  </div>
</template>

<script>
import StepperInputButton from '@/components/atoms/StepperInputButton.vue';
import StepperInputSummary from '@/components/atoms/StepperInputSummary.vue';
import MinusIcon from '@/assets/icons/minus.svg';
import AddIcon from '@/assets/icons/add.svg';

export default {
  components: {
    StepperInputButton,
    StepperInputSummary,
    MinusIcon,
    AddIcon
  },
  props: {
    count: {
      type: Number,
      default: 0
    },
    removeButtonDisabled: {
      type: Boolean,
      default: false
    },
    addButtonDisabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: null
    }
  },
  methods: {
    onRemoveClick() {
      this.$emit('remove');
    },
    onAddClick() {
      this.$emit('add');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.stepper-input {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: rem(32px auto 50px);
}
</style>
